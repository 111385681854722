// import ethereumLogoUrl from 'assets/images/ethereum-logo.png'

export enum SupportedChainId {
  //MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 6,
  POLYGON = 137,
  BINANCE = 56,
  BINANCETEST = 97,
  POLYGONTEST = 80001,
  PHOENIX = 13381,
  DOGEMAINNET = 2000,
  TRESMAINNET = 6066,
  TRESTESTNET = 6065,
}

export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  //SupportedChainId.MAINNET,
  SupportedChainId.BINANCE,
  SupportedChainId.POLYGON,

  SupportedChainId.POLYGONTEST,
  SupportedChainId.BINANCETEST,
  SupportedChainId.ROPSTEN,
  SupportedChainId.RINKEBY,
  SupportedChainId.GOERLI,
  SupportedChainId.KOVAN,
  SupportedChainId.PHOENIX,
  SupportedChainId.DOGEMAINNET,
  SupportedChainId.TRESMAINNET,
  SupportedChainId.TRESTESTNET,
];

export const L1_CHAIN_IDS = [
  // SupportedChainId.MAINNET,
  SupportedChainId.ROPSTEN,
  SupportedChainId.RINKEBY,
  SupportedChainId.GOERLI,
  SupportedChainId.KOVAN,
  SupportedChainId.BINANCE,
  SupportedChainId.POLYGON,
  SupportedChainId.BINANCETEST,
  SupportedChainId.POLYGONTEST,
  SupportedChainId.PHOENIX,
  SupportedChainId.DOGEMAINNET,
  SupportedChainId.TRESMAINNET,
  SupportedChainId.TRESTESTNET,
] as const;

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number];

export interface L1ChainInfo {
  readonly blockWaitMsBeforeWarning?: number;
  readonly docs: string;
  readonly explorer: string;
  readonly infoLink: string;
  readonly label: string;
  readonly logoUrl?: string;
  readonly rpcUrls?: string[];
  readonly nativeCurrency: {
    name: string; // 'Goerli ETH',
    symbol: string; // 'gorETH',
    decimals: number; //18,
  };
}
export interface L2ChainInfo extends L1ChainInfo {
  readonly bridge: string;
  readonly logoUrl: string;
  readonly statusPage?: string;
}

export type ChainInfo = { readonly [chainId: number]: L1ChainInfo } & {
  readonly [chainId in SupportedL1ChainId]: L1ChainInfo;
};

export const CHAIN_INFO: ChainInfo = {
  [SupportedChainId.ROPSTEN]: {
    docs: "",
    explorer: "https://ropsten.etherscan.io/",
    infoLink: "",
    label: "Ropsten",
    nativeCurrency: { name: "Ethereum", symbol: "ropETH", decimals: 18 },
  },
  [SupportedChainId.BINANCE]: {
    docs: "",
    explorer: "https://www.bscscan.com/",
    infoLink: "",
    label: "Binance",
    nativeCurrency: { name: "Binance", symbol: "BNB", decimals: 18 },
  },
  [SupportedChainId.BINANCETEST]: {
    docs: "",
    explorer: "https://testnet.bscscan.com/",
    infoLink: "",
    label: "BSC Testnet",
    nativeCurrency: { name: "Binance", symbol: "tBNB", decimals: 18 },
  },
  [SupportedChainId.POLYGON]: {
    docs: "",
    explorer: "https://polygonscan.com/",
    infoLink: "",
    label: "Polygon",
    nativeCurrency: { name: "Polygon", symbol: "POL", decimals: 18 },
  },
  [SupportedChainId.POLYGONTEST]: {
    docs: "",
    explorer: "https://mumbai.polygonscan.com/",
    infoLink: "",
    label: "Mumbai Testnet",
    nativeCurrency: { name: "Polygon", symbol: "POL", decimals: 18 },
  },
  /*
  [SupportedChainId.MAINNET]: {
    docs: '',
    explorer: 'https://etherscan.io/',
    infoLink: '',
    label: 'Ethereum',
    nativeCurrency: { name: 'Ethereum', symbol: 'ETH', decimals: 18 },
  },
  */
  [SupportedChainId.RINKEBY]: {
    docs: "",
    explorer: "https://rinkeby.etherscan.io/",
    infoLink: "",
    label: "Rinkeby",
    nativeCurrency: { name: "Ethereum", symbol: "rinkETH", decimals: 18 },
  },
  [SupportedChainId.ROPSTEN]: {
    docs: "",
    explorer: "https://ropsten.etherscan.io/",
    infoLink: "",
    label: "Ropsten",
    nativeCurrency: { name: "Ethereum", symbol: "ropETH", decimals: 18 },
  },
  [SupportedChainId.KOVAN]: {
    docs: "",
    explorer: "https://kovan.etherscan.io/",
    infoLink: "",
    label: "Kovan",
    nativeCurrency: { name: "Ethereum", symbol: "kovETH", decimals: 18 },
  },
  [SupportedChainId.GOERLI]: {
    docs: "",
    explorer: "https://goerli.etherscan.io/",
    infoLink: "",
    label: "Görli",
    nativeCurrency: { name: "Ethereum", symbol: "görETH", decimals: 18 },
  },
  [SupportedChainId.PHOENIX]: {
    docs: "",
    explorer: "https://phoenixplorer.com",
    infoLink: "",
    label: "Phoenix Mainnet",
    nativeCurrency: { name: "Phoenix", symbol: "PHX", decimals: 18 },
  },
  [SupportedChainId.DOGEMAINNET]: {
    docs: "",
    explorer: "https://explorer.dogechain.dog/",
    infoLink: "",
    label: "Dogechain Mainnet",
    nativeCurrency: { name: "Doge", symbol: "DOGE", decimals: 18 },
  },
  [SupportedChainId.TRESMAINNET]: {
    docs: "https://docs.treschain.io",
    explorer: "https://treschain.io/",
    infoLink: "",
    label: "Tres Leches Mainnet",
    nativeCurrency: { name: "Tres Leches", symbol: "TRES", decimals: 18 },
  },
  [SupportedChainId.TRESTESTNET]: {
    docs: "https://docs.treschain.io",
    explorer: "https://tres",
    infoLink: "",
    label: "Tres Leches Testnet",
    nativeCurrency: { name: "Tres Leches", symbol: "tTRES", decimals: 18 },
  },
};
